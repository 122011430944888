<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我和先生都是70后，多年来都服务于国际教育事业，我们的女儿在国内就读于国际学校小学部。在孩子的教育问题上，我和先生很早就达成共识把女儿送到美国留学，从高中起就让她接受世界的教育。我们夫妻两人也一直怀揣着一个“美国梦”，希望孩子未来能在美国学有所成的同时，为人父母在年迈时仍可以陪伴在儿女身边，有朝一日可以在美利坚的土地上生活与发展。</p>
        <p>在女儿开启留学生涯后，我们发现她学得比之前更快乐，孩子主动提出了打算在美国念大学以及研究生的想法，这一点更加坚定了我们移居的打算。于是，我和先生开始关注美国投资移民，了解到了EB5。由于身边尚未有朋友涉入这个领域，我和先生两人起初是抱着“摸石头过河”的心态，自己做各种功课。一个偶然的机会，我们接触到了跃迁出国，在和跃迁的市场顾问接触了之后，感受到了他们专业的背景和丰富的行业经验，也很看好跃迁选择的移民项目，我们果断选择了跃迁作为我们移民美国之路的“领路人”。</p>
        <p>EB5成功的关键不仅在于选择优质可靠的投资项目，而且需要专业的文案团队指导我们做资金来源的合法性证明。当时我们正打算出售名下的一处房产，跃迁的文案顾问建议我们用该笔资金作为投资款的直接来源，是简单和直接的方式。在筹集资金和准备文件的过程中，跃迁的工作人员给了我们很多的帮助，资料他们会指导我们如何调取；未来需要的资料会预先提醒我们该如何备份。整个繁琐的过程一步一步变得清晰而有条理。最终，在资金落实的同时我们的申请文件也基本齐备了。向美国移民局递交了I-526申请，在9个月后获得了I-526的批准函。</p>
        <p>在整个申请过程中，跃迁人一直相伴在我们左右，时时更新项目的施工进度和个人申请的进展。大家共同努力使我们顺利地通过了广州领事馆的面试，拿到了移民签证和绿卡。如今我们已在美国生活了一年多，这期间还得益于跃迁体贴的安居登陆服务。每每回顾自己的移民之路，我和先生都很庆幸当初选择了EB5，更庆幸选择了跃迁出国。</p>
        <p>
        </p>

      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases10",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>